@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@google-btn-container-cls: ~'@{ra-prefix}-google-btn-container';

:export {
  googleBtnContainerCls: @google-btn-container-cls;
}
.@{google-btn-container-cls} {
  display: inline-block;
  max-width: 320px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 5px auto;
  color: @ra-color-text;
  line-height: 32px;
}
