@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@facebook-btn-cls: ~'@{ra-prefix}-facebook-btn';
@facebook-color: #4267b2;

:export {
  facebookBtnCls: @facebook-btn-cls;
}

.@{facebook-btn-cls}.ra-btn.ra-btn-primary.ant-btn {
  display: block;
  width: 100%;
  max-width: 320px;
  padding-left: 5px;
  padding-right: 5px;
  margin: 5px auto;
  background-color: @facebook-color;
  border-color: @facebook-color;
  border-radius: 4px;
  color: @ra-color-white;
  line-height: 32px;
  text-transform: none;

  &:hover,
  &:focus {
    background-color: @facebook-color;
    border-color: @facebook-color;
    color: @ra-color-white;
  }

  > .anticon,
  > .ant-btn-icon {
    float: left;
    font-size: 24px;

    > svg,
    > .anticon > svg {
      vertical-align: inherit;
    }
  }
}
